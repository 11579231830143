<template>
    <div>
      <m-page-title title="Amazon" />
      <div class="row">
        <div class="col-12">
          <div class="card"></div>
            <div class="card-header">
              <h4 class="mb-0">Orders</h4>
            </div>
            <b-row class="align-items-center">
            <b-colxx md="5" sm="12"
              ><b-form
                id="customer-search-main"
                @submit.prevent="getAmazonOrders"
              >
                <b-form-group
                  id="search-fieldset"
                  label="Order Number"
                  label-for="search-order-number"
                  class="field-label-form-group"
                >
                  <b-input-group class="">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-search"></i
                      ></span>
                    </div>
                    <b-form-input
                      id="search-order-number-input"
                      v-model="searchOrderNumber"
                      placeholder="Search by order number..."
                      type="text"
                    >
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-from-fieldset"
                label="From"
                label-for="search-from-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-from-date"
                  v-model="searchFromDate"
                  type="date"
                />
              </b-form-group>
            </b-colxx>
            <b-colxx md="2" sm="12">
              <b-form-group
                id="search-to-fieldset"
                label="To"
                label-for="search-to-date"
                class="field-label-form-group"
              >
                <b-form-input
                  id="search-to-date"
                  v-model="searchToDate"
                  type="date"
                />
              </b-form-group> </b-colxx
          >
          <b-colxx md="3" sm="12">
              <b-button variant="success" @click="getAmazonOrders()"
                >Search</b-button
              >
              <b-button variant="info" @click="clearSearchFilters()"
                >Clear</b-button
              >
            </b-colxx>
          </b-row>
            <div class="card-body">
              <b-table
              id="amazon-order-table"
              ref="__amazonOrderTable"
              responsive
              head-variant="dark"
              :items="amazonOrders"
              :fields="amazon_order_list_fields"
              per-page="0"
              >
                <template #cell(action)="row">
                  <i
                    class="fas fa-eye text-default icon-pointer"
                    @click="getOrderDetails(row.item.AmazonOrderId)"
                  />
                </template>
                <template #cell(OrderTotal)="row">
                  <span>{{ row.item.OrderTotal.CurrencyCode }} {{ row.item.OrderTotal.Amount }}</span>
                </template>
              </b-table>
            </div>
            <div v-if="showOrderDetails" class="container mt-4">
        <m-page-title title="Amazon Order Details" />
        
        <div class="card mb-4">
            <div class="card-header">
                <h4 class="mb-0">Order Summary</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <h5>Order ID:</h5>
                        <p class="font-weight-bold">{{ amazonOrderDetails.AmazonOrderId }}</p>
                    </div>
                    <div class="col-md-6">
                        <h5>Buyer Email:</h5>
                        <p class="font-weight-bold">{{ amazonOrderDetails.BuyerEmail }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h5>Status:</h5>
                        <p class="font-weight-bold">{{ amazonOrderDetails.OrderStatus }}</p>
                    </div>
                    <div class="col-md-6">
                        <h5>Purchase Date:</h5>
                        <p class="font-weight-bold">{{ amazonOrderDetails.PurchaseDate }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5>Shipping Address:</h5>
                        <p class="font-weight-bold">
                            {{ amazonOrderDetails.ShippingAddress.City }},
                            {{ amazonOrderDetails.ShippingAddress.StateOrRegion }},
                            {{ amazonOrderDetails.ShippingAddress.PostalCode }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5>Order Total:</h5>
                        <p class="font-weight-bold">
                            {{ amazonOrderDetails.OrderTotal.CurrencyCode }} {{ amazonOrderDetails.OrderTotal.Amount }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <h4 class="mb-0">Order Items</h4>
            </div>
            <div class="card-body">
                <b-table
                    :items="amazonOrderItems"
                    :fields="orderItemFields"
                    striped
                    hover
                    responsive
                >
                    <template #cell(ItemPrice)="row">
                        <span>{{ row.item.ItemPrice.CurrencyCode }} {{ row.item.ItemPrice.Amount }}</span>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import django_session from "@/api/django_session";
  export default {
    name: "AmazonHome",

    data() {
      return {
        amazonOrders: [],
        amazon_order_list_fields: [
          { key: 'action', label: 'Action' },
          { key: 'PurchaseDate', label: 'Order Date' },
          { key: 'AmazonOrderId', label: 'Order ID' },
          { key: 'OrderStatus', label: 'Order Status' },
          { key: 'OrderTotal', label: 'Order Total' },
        ],
        showOrderDetails: false,
        orderItemFields: [
          { key: 'Title', label: 'Title' },
          { key: 'QuantityOrdered', label: 'Quantity' },
          { key: 'QuantityShipped', label: 'Shipped' },
          { key: 'ItemPrice', label: 'Item Price' }
        ],
        amazonOrderDetails: {},
        amazonOrderItems: [],
        searchOrderNumber: "",
        searchFromDate: null,
        searchToDate: null
      };
    },

    mounted() {
      document.title = "Amazon";
    },

    methods: {
      getAmazonOrders() {
        django_session.get("erp/amazon/get-orders/", {
          params: {
            searchOrderNumber: this.searchOrderNumber,
            searchFromDate: this.searchFromDate,
            searchToDate: this.searchToDate
          }
        }).then((response) => {
          console.log(response);
          this.amazonOrders = response.data.data.payload.Orders;
        });
      },

      getOrderDetails(orderId) {
        // If the order is pending, then all of the data is not available
        // So, we need to wait for the order to be fulfilled
        django_session.get(`erp/amazon/get-order/?orderId=${orderId}`).then((response) => {
          console.log(response);
          this.amazonOrderDetails = response.data;
          this.amazonOrderItems = response.data.OrderItems;
          this.showOrderDetails = true;
        });
      },
    },
  };
  </script>
  